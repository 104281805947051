import React from 'react';

class Quotes extends React.Component {

    render() {
        return (
            <div className="py-4 lg:px-4 lg:w-1/2">
                <div className="bg-pink dark:bg-lightgrey dark:border dark:border-ledgreen rounded-lg">
                    <div className="bg-pink dark:bg-lightgrey border dark:border-ledgreen rounded-t-lg select-none">
                        <h2 className="text-center text-bluepurp dark:text-white font-normal text-3xl py-2">Quotes</h2>
                    </div>
                    <div className="bg-white dark:bg-lightgrey dark:border-ledgreen rounded-lg px-8 py-4">
                        <div className="flex">
                            <div className="w-1/3 px-2 select-none">
                                <button className={"w-full py-2 border font-semibold border-bluepurp dark:border-darkgrey border-lg rounded-lg hover:bg-bluepurp dark:hover:bg-ledgreen hover:text-white " + (this.props.currentQuoteMode === 1 ? "bg-bluepurp dark:bg-ledgreen text-white" : "bg-white dark:bg-darkgrey dark:text-white text-bluepurp ")} data-value="1" onClick={this.props.onHandleQuote}>Single</button>
                            </div>
                            <div className="w-1/3 px-2 select-none">
                                <button className={"w-full py-2 border font-semibold border-bluepurp dark:border-darkgrey border-lg rounded-lg hover:bg-bluepurp dark:hover:bg-ledgreen hover:text-white " + (this.props.currentQuoteMode === 2 ? "bg-bluepurp dark:bg-ledgreen text-white" : "bg-white dark:bg-darkgrey dark:text-white text-bluepurp ")} data-value="2" onClick={this.props.onHandleQuote}>Double</button>
                            </div>
                            <div className="w-1/3 px-2 select-none">
                                <button className={"w-full py-2 border font-semibold border-bluepurp dark:border-darkgrey border-lg rounded-lg hover:bg-bluepurp dark:hover:bg-ledgreen hover:text-white " + (this.props.currentQuoteMode === 0 ? "bg-bluepurp dark:bg-ledgreen text-white" : "bg-white dark:bg-darkgrey dark:text-white text-bluepurp ")} data-value="0" onClick={this.props.onHandleQuote}>None</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Quotes;