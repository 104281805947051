import React from 'react';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import OriginalData from './components/OrignalData';
import DelimitedData from './components/DelimitedData';
import Delimiters from './components/Delimiters';
import Quotes from './components/Quotes';
import PrefModal from './components/PrefModal';
import SmError from './components/SmError';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      quoteMode: 2,
      delim: 1,
      delimiter: ",",
      autoCopy: 'disabled',
      showModal: false,
      setShowModal: false,
      releaseDate: '09/25/2020',
      version: '2.4.1',
    };

  }

  componentDidMount() {

    const isSetup = localStorage.getItem('isSetup')
    //this.setState({ isSetup: isSetup })

    if (isSetup === 'yes') {
      const autoCopyActive = localStorage.getItem('autoCopy')
      this.setState({ autoCopy: autoCopyActive })
      //console.log('current autocopy status is:' + this.state.autoCopy);
    } else {
      //console.log('current autocopy pre-status is:' + this.state.autoCopy);
      localStorage.setItem('autoCopy', 'disabled');
      localStorage.setItem('isSetup', 'yes');
    }

  }

  updateCopyStatus = () => {
    //console.log('Button Clicked!');

    if (this.state.autoCopy === 'default') {
      this.setState({ autoCopy: 'enabled' })

    } else if (this.state.autoCopy === 'enabled') {
      this.setState({ autoCopy: 'disabled' })

    } else if (this.state.autoCopy === 'disabled') {
      this.setState({ autoCopy: 'enabled' })
    }

    //console.log(this.state.autoCopy);

  }

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  }

  handleCloseSave = () => {
    this.setState({
      showModal: false,
      setShowModal: false
    });
    localStorage.setItem('isSetup', 'yes');
    localStorage.setItem('autoCopy', this.state.autoCopy);
  }

  handleOpen = () => {
    this.setState({
      showModal: true,
      setShowModal: true
    });
  }

  handleQuote = (e) => {
    let selectedValue = Number(e.target.getAttribute('data-value'));
    this.setState({
      quoteMode: selectedValue
    }, function () {
      this.delimitUsingState();
    });
  }

  handleDelim = (e) => {
    let selectedValue = Number(e.target.getAttribute('data-value'));
    if (selectedValue === 0) {
      this.state.delimiter = ";"
      this.state.delim = 0
    } else if (selectedValue === 1) {
      this.state.delimiter = ","
      this.state.delim = 1
    } else if (selectedValue === 2) {
      this.state.delimiter = "|"
      this.state.delim = 2
    } else {
      console.log("Could Not Match Delim to Delimiter")
    }
    let delimiter = this.state.delimiter
    this.setState({
      delimiter: delimiter
    }, function () {
      this.delimitUsingState();
    });

  }

  delimitUsingState = () => {
    let delimiter = this.state.delimiter;
    let content = document.getElementById("Org").value;
    var contentList = content.split("\n");

    let quoteMode = this.state.quoteMode;
    var encap;
    switch (quoteMode) {
      case 0:
        encap = "";
        break;
      case 1:
        encap = "'";
        break;
      case 2:
        encap = "\"";
        break;

      default:
        console.warn("Invalid Quote!");
        return;
    }
    contentList = contentList.map(line => line.trim()).filter(line => line !== "");
    contentList = contentList.map(line => `${encap}${line}${encap}`);

    let proContent = contentList.join(delimiter);
    document.getElementById("Pro").value = proContent;

    if (this.state.autoCopy === true) {
      this.handleCopy()
    } else {
      return
    }

  }

  handleClear = () => {
    document.getElementById("Org").value = " ";
    document.getElementById("Pro").value = " ";

  }

  handleCopy = () => {
    var copyItems = document.getElementById("Pro");
    copyItems.select();
    document.execCommand("copy");
  }

  handleChange = () => {
    this.delimitUsingState()

    if (this.state.autoCopy === 'enabled') {
      this.handleCopy()
    } else {
      return;
    }



  }

  render() {
    return (
      <div className="lg:flex flex-col h-full">
        {/* Top Div Start*/}
        {/* Header Div Start*/}
        <header >
          <Header onHandleModalStatus={this.handleOpen} onHandleCopy={this.handleCopy} onHandleClear={this.handleClear} onHandleDarkMode={this.handleDarkMode} />
        </header>
        {/* Header Div End*/}
        {/* Main Div Start*/}
        <main className="bg-bluepurp dark:bg-darkgrey h-full flex-grow">
          <SmError />
          <div className=" hidden h-full md:flex md:flex-col md:px-16 lg:px-8 xl:px-12 py-8">
            {/* Options Start*/}
            <div className="lg:flex">
              {/* Delimiters Start*/}
              <Delimiters currentDelimiter={this.state.delim} onHandleDelim={this.handleDelim} />
              {/* Quotes Start*/}
              <Quotes currentQuoteMode={this.state.quoteMode} onHandleQuote={this.handleQuote} />
            </div>
            {/* Text Areas Start*/}
            <div className="flex-grow lg:flex">
              <OriginalData onHandleChange={this.handleChange} />
              <DelimitedData />
            </div>
          </div>
        </main>
        {/* Main Div End*/}
        <footer>
          <Footer currentVersion={this.state.version} />
        </footer>
        {/*Top Div End*/}

        {this.state.showModal ? (
          <PrefModal currentAutoCopy={this.state.autoCopy} onHandleClose={this.handleClose} onHandleCloseSave={this.handleCloseSave} onUpdateCopyStatus={this.updateCopyStatus} currentVersion={this.state.version} date={this.state.releaseDate} />
        ) : null}
      </div>
    );
  }
}

export default App;

