import React from 'react';

class Header extends React.Component {

    render() {
        return (
            <div className="flex md:px-12 lg:px-16 bg-white dark:bg-lightgrey">
                <div className="md: w-1/2 lg:w-3/5 select-none">
                    <h1 className="text-bluepurp dark:text-ledgreen px-4 pt-2 font-semibold text-3xl"> DELIMITER</h1>
                    <h1 className="text-bluepurp dark:text-ledgreen pl-4 pb-2 text-sm font-normal italic"> a Callisto Enterprises utility</h1>
                </div>

                <div className="hidden md:block md: w-1/2 lg:w-2/5 md:flex">
                    <div className="w-1/3 py-4 px-4 select-none">
                        <button className="w-full py-2 border border-bluepurp dark:border-darkgrey border-lg rounded-lg bg-bluepurp dark:bg-darkgrey text-white italic hover:bg-white dark:hover:border-ledgreen dark:hover:bg-darkgrey dark:hover:text-ledgreen hover:text-bluepurp dark:hover:text-white hover:font-bold" onClick={this.props.onHandleModalStatus}>Preferences</button>
                    </div>
                    <div className="w-1/3 py-4 px-4 select-none">
                        <button className="w-full py-2 border border-bluepurp dark:border-darkgrey border-lg rounded-lg bg-bluepurp dark:bg-darkgrey text-white font-semibold hover:bg-white dark:hover:border-ledgreen dark:hover:bg-darkgrey dark:hover:text-ledgreen hover:text-bluepurp dark:hover:text-white hover:font-bold" onClick={this.props.onHandleCopy}>COPY</button>
                    </div>
                    <div className="w-1/3 py-4 px-4 select-none">
                        <button className="w-full py-2 border border-bluepurp dark:border-darkgrey border-lg rounded-lg bg-redpink dark:bg-darkgrey text-white font-semibold hover:bg-red-600 dark:hover:text-red-600 dark:hover:bg-darkgrey dark:hover:border-red-600 " onClick={this.props.onHandleClear}>CLEAR</button>
                    </div>
                </div>
            </div>
        )
    }

}

export default Header;