import React from 'react';

class PrefModal extends React.Component {

    render() {
        return (
            <div>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white dark:bg-darkgrey">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 dark:border-ledgreen rounded-t">
                                <h3 className="text-3xl italic text-bluepurp dark:text-ledgreen font-semibold">
                                    Preferences
                  </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={this.props.onHandleClose}
                                >
                                    <span className="bg-transparent text-black dark:text-ledgreen opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                <h1 className="text-bluepurp dark:text-white font-semibold">Options</h1>
                                <div className="pt-4 pb-4">
                                    <button className={"w-full py-2 font-semibold border border-bluepurp dark:border-ledgreen border-lg rounded-lg text-white " + (this.props.currentAutoCopy === 'disabled' ? "bg-red-600" : "bg-green-600")} onClick={this.props.onUpdateCopyStatus}>Auto-copy {this.props.currentAutoCopy}</button>
                                </div>
                                <h1 className="text-bluepurp dark:text-white font-semibold">Version Notes</h1>
                                <p className="my-2 pl-2 text-bluepurp dark:text-white text-sm leading-relaxed">- Version  {this.props.currentVersion}</p>
                                <p className="my-2 pl-2 text-bluepurp dark:text-white text-sm leading-relaxed">- Release Date:  {this.props.date}</p>
                                <p className="my-2 pt-4 pl-2 text-bluepurp dark:text-white text-md font-bold leading-relaxed">- Introducing Dark Mode Support!</p>
                                <p className="my-2 pl-4 text-bluepurp dark:text-white text-sm leading-relaxed">- Automatically updates based on your browser settings</p>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-center p-6 border-t border-solid border-gray-300 dark:border-ledgreen rounded-b">
                                <button
                                    className="uppercase px-6 py-2 text-sm mr-1 mb-1 border border-bluepurp border-lg rounded-lg bg-bluepurp dark:bg-ledgreen text-white font-semibold hover:bg-white hover:bg-darkgrey dark:border-ledgreen dark:hover:text-ledgreen hover:text-bluepurp"
                                    onClick={this.props.onHandleCloseSave}
                                >
                                    Save & Exit
                  </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
        )
    }

}

export default PrefModal;