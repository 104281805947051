import React from 'react';

class OriginalData extends React.Component {

    render() {
        return (
            <div className="h-full py-4 lg:px-4 lg:w-1/2">
                <div className="h-full bg-pink dark:bg-lightgrey rounded-lg dark:border dark:border-ledgreen flex flex-col">
                    <div className="bg-pink dark:bg-lightgrey border dark:border-ledgreen rounded-t-lg select-none">
                        <h2 className="text-center text-bluepurp dark:text-white font-normal text-3xl py-2">Original Data</h2>
                    </div>
                    <div className="bg-white dark:bg-lightgrey rounded-lg px-8 py-4 flex-grow">
                        <textarea id="Org" name="Org" placeholder="Enter data to be delimited" onChange={this.props.onHandleChange} className="focus:outline-none py-2 border border-lg border-bluepurp dark:border-ledgreen dark:bg-darkgrey dark:text-white rounded-lg w-full pl-2 resize-none md:h-64 lg:h-full" ></textarea>
                    </div>
                </div>
            </div>
        )
    }

}

export default OriginalData;