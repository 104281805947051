import React from 'react';

class SmError extends React.Component {

    render() {
        return (
            <div className="md:hidden sm:block px-4 py-64">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172" className="h-16 w-16 text-white dark:text-ledgreen mx-auto">
                    <g fill="none">
                        <path d="M86 21.71c-4.036 0-8.07 1.88-10.33 5.64L12.626 132.234C7.98 139.96 13.924 150.5 22.942 150.5h126.102c9.015 0 14.976-10.54 10.33-18.267L96.33 27.351C94.07 23.59 90.036 21.71 86 21.71zm0 16.265l59.027 98.192H26.973zM78.833 64.5v35.833h14.334V64.5zm0 50.167V129h14.334v-14.333z" fill="currentColor" />
                    </g>
                </svg>
                <h1 className="text-center text-white dark:text-ledgreen text-6xl py-2">Oops!</h1>
                <h1 className="text-center text-white dark:text-ledgreen text-xl pt-2 pb-8">Delimiter Version 2.0 is no longer compatible for use with Small Screens & Moblie Devices in Portrait mode!</h1>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172" className="h-8 w-8 dark:text-ledgreen text-white mx-auto">
                    <g fill="none">
                        <path d="M21.5 21.5V129h43v-14.333H35.833V35.833h43v64.5h14.334V21.5zm86 0v14.333h14.333v14.334H107.5l21.5 21.5 21.5-21.5h-14.333V35.833c0-7.833-6.5-14.333-14.334-14.333zM50.167 86v14.333H64.5V86zm57.333 0v14.333h14.333V86zm28.667 0v14.333H150.5V86zm0 28.667V129H150.5v-14.333zm-86 28.666v14.334H64.5v-14.334zm28.666 0v14.334h14.334v-14.334zm28.667 0v14.334h14.333v-14.334zm28.667 0v14.334H150.5v-14.334z" fill="currentColor" />
                    </g>
                </svg>
                <h1 className="text-center text-white dark:text-ledgreen text-xl py-2">Rotate your device to continue</h1>
            </div>
        )
    }

}

export default SmError;
