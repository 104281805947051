import React from 'react';

class Footer extends React.Component {

    render() {
        return (
            <div className="bg-white dark:bg-lightgrey md:flex md:items-center w-full select-none">
                <a href="https://callisto.enterprises" className="hidden md:block md:w-1/3 text-bluepurp dark:text-white px-4 py-2 text-sm font-semibold text-center hover:underline">About Us</a>
                <h1 className="md:w-1/3 text-bluepurp dark:text-white px-4 py-2 text-sm font-semibold text-center">&copy; 2020 Callisto Enterprises LLC.</h1>
                <h1 className="hidden md:block md:w-1/3 text-bluepurp dark:text-white px-4 py-2 text-sm font-semibold text-center">Version  {this.props.currentVersion}</h1>
            </div>
        )
    }
}

export default Footer;